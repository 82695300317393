import React, { useState, useEffect, useContext } from 'react';
import { useTheme, Card, CardContent, CardMedia, Typography, Modal, Box, Button, TextField, FormControl, InputLabel, Select, Chip, MenuItem, SelectChangeEvent, FormControlLabel, Checkbox, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadComponent from '../../common-components/UploadComponent/UploadComponent';
import { getList, insertOne, deleteOne, markAsRead } from '../../../services/requests/message';
import { IMessage, IUserMessage } from '../../../interfaces/IMessage';
import { Controller, set, useForm } from 'react-hook-form';
import { countiesWithValues } from '../../../constants/counties';
import { getUserData } from '../../../helpers/storage';
import { confirmDeletePopup } from '../../../redux/services/User/UserRequestHandlers';
import { useGlobalStyles } from '../../../customStyles';


const Messages = () => {
  const theme = useTheme();
  const cssClass = useGlobalStyles();
  const { control, handleSubmit, setValue, formState: { errors }, reset } = useForm();
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState<IUserMessage[]>([]);
  const [selectedMessage, setSelectedMessage] = useState<IMessage | null>(null);
  const [readMessages, setReadMessages] = useState<number[]>([]);
  const [addOpen, setAddOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const userData = getUserData();


  useEffect(() => {
    fetchMessages();
  }, []);



  const fetchMessages = async () => {
    try {
      const response = await getList(
        {},
        (data: IUserMessage[]) => data,
        (error: unknown) => console.error(error)
      );

      if (Array.isArray(response)) {
        setMessages(response);
        const readMessageIds = response
          .filter((message) => message.IsRead)
          .map((message) => message.Message.ID);

        setReadMessages(readMessageIds);
      } else {
        console.error('Expected an array but got:', response);
        setMessages([]);
        setReadMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setMessages([]);
      setReadMessages([]);
    }
  };

  useEffect(() => {
    console.log("Message updated as read:", readMessages);
  }, [readMessages])

  const handleOpen = async (message: IMessage) => {
    setSelectedMessage(message);
    setOpen(true);

    if (!readMessages.includes(message.ID)) {
      try {
        console.log("Calling markAsRead with messageId:", message.ID);

        const response = await markAsRead({ messageId: message.ID });

        if (response) {
          console.log("Message marked as read:", readMessages);

          setReadMessages(prev => [...prev, message.ID]);
        }
      } catch (error) {
        console.error("Error marking message as read:", error);
      }
    } else {
      console.log("Message is already marked as read.");
    }
  };

  const onFileUpload = (files: File[]) => {
    setUploadedFile((prevFiles) => [...prevFiles, ...files]);
    setValue('uploadedFiles', [...uploadedFile, ...files]);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMessage(null);
  };

  const handleAddOpen = () => {
    reset();
    setAddOpen(true);
  };

  const handleDeleteMessage = async (messageId: number) => {
    try {
      const isConfirmed = await confirmDeletePopup();
      if (!isConfirmed) return;
      await deleteOne({ messageId });
      setMessages((prevMessages) => prevMessages.filter((message) => message.Message.ID !== messageId));
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleAddClose = () => {
    setAddOpen(false);
    setUploadedFile([]);
    reset();
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  const handleAddMessage = async (data: any) => {
    const newMessage = {
      Title: data.title,
      FullText: data.fullText,
      states: data.selectedStates,
      //@ts-ignore
      FK_StatischeDateien: uploadedFile[0]?.ID || null,
    };

    try {
      await insertOne(
        newMessage,
        () => {
          fetchMessages();
          handleAddClose();
        },
        (error: unknown) => console.error(error)
      );
    } catch (error) {
      console.error('Error adding new message:', error);
    }
  };


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: theme.spacing(2) }}>
      {userData.Admin &&
        <Button variant="contained" color="primary" onClick={handleAddOpen} sx={{ marginBottom: theme.spacing(2) }}>
          Neue Nachricht hinzufügen
        </Button>
      }
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(450px, 1fr))',
          gap: theme.spacing(2),
          width: '100%',
          gridAutoRows: '1fr',
        }}
      >
        {messages.map((message) => (
          <Card
            key={message.ID}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              borderRadius: 2,
              boxShadow: 3,
              width: '100%'
            }}
          >
            <CardMedia
              component="img"
              height="140"
              image={
                message.Message.StaticFile?.Uri
                  ? `${process.env.REACT_APP_IMAGE_URL}${message.Message.StaticFile.Uri}`
                  : '/placeholder-image.png'
              }
              alt={message.Message.Title}
            />
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: message.IsRead ? 'gray' : 'black' }}
                >
                  {message.Message.Title}
                  {readMessages.includes(message.Message.ID) && (
                    <CheckCircleIcon sx={{ fontSize: 18, color: 'green', marginLeft: 1 }} />
                  )}
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {truncateText(message.Message.FullText, 250)}
                </Typography>
              </Box>
              <Button
                variant="contained"
                onClick={() => handleOpen(message.Message)}
                sx={{
                  backgroundColor: readMessages.includes(message.Message.ID) ? 'gray' : theme.palette.primary.light,
                }}
              >
                {readMessages.includes(message.Message.ID) ? 'Gelesen' : 'Mehr lesen'}
              </Button>
              {userData.Admin && (
                <Button
                  onClick={() => handleDeleteMessage(message.Message.ID)}
                  sx={{ marginTop: theme.spacing(1) }}
                >
                  Löschen
                </Button>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            width: '90%',
            maxWidth: 500,
            margin: 'auto',
            marginTop: '10%',
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: 24,
            padding: theme.spacing(3),
          }}
        >
          {selectedMessage && (
            <>
              <Typography variant="h6" gutterBottom>
                {selectedMessage.Title}
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                {selectedMessage.FullText}
              </Typography>
              <Button variant="contained" color="secondary" onClick={handleClose}>
                Schließen
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleAddClose}
        aria-labelledby="add-blog-modal"
        aria-describedby="add-blog-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 750,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <Typography id="add-blog-modal" variant="h6">
            Neue Nachricht hinzufügen
          </Typography>

          {/* Titel Eingabefeld */}
          <Controller
            name="title"
            control={control}
            defaultValue=""
            rules={{ required: 'Titel ist erforderlich' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Titel"
                fullWidth
                error={!!errors.title}
                helperText={errors.title ? String(errors.title.message) : ''}
              />
            )}
          />

          {/* Text Eingabefeld */}
          <Controller
            name="fullText"
            control={control}
            defaultValue=""
            rules={{ required: 'Text ist erforderlich' }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Text"
                multiline
                rows={4}
                fullWidth
                error={!!errors.fullText}
                helperText={errors.title?.message?.toString()}
              />
            )}
          />

          <Box>
            <Controller
              name="selectedStates"
              control={control}
              defaultValue={[]} // Standardmäßig ein leerer Array
              rules={{
                validate: (states) =>
                  states && states.length > 0 ? true : 'Bitte wählen Sie mindestens ein Bundesland aus.',
              }}
              render={({ field }) => (
                <Box>
                  <Typography variant="h6">Wählen Sie die Bundesländer:</Typography>
                  {countiesWithValues.map((county) => (
                    <FormControlLabel
                      key={county.value}
                      label={county.name}
                      control={
                        <Checkbox
                          checked={field.value.some(
                            (state: { value: string; name: string }) => state.value === county.value
                          )}
                          onChange={() => {
                            const newValue = field.value.some(
                              (state: { value: string; name: string }) => state.value === county.value
                            )
                              ? field.value.filter(
                                (state: { value: string; name: string }) => state.value !== county.value
                              )
                              : [...field.value, { value: county.value, name: county.name }];
                            field.onChange(newValue);
                          }}
                        />
                      }
                    />
                  ))}
                  {errors.selectedStates && (
                    <Typography color="error" variant="body2">
                      {errors.selectedStates.message?.toString()}
                    </Typography>
                  )}
                </Box>
              )}
            />
          </Box>

          <Controller
            name="uploadedFiles"
            control={control}
            rules={{
              validate: (files) => files && files.length > 0 || 'Bitte laden Sie mindestens eine Datei hoch.',
            }}
            render={({ field }) => (
              <>
                <UploadComponent
                  addToSWVInfo={false}
                  onFileUpload={(files) => {
                    onFileUpload(files);
                    field.onChange([...uploadedFile, ...files]);
                  }}
                />
                {errors.uploadedFiles && (
                  <Typography color="error" variant="body2">
                    {errors.uploadedFiles.message?.toString()}
                  </Typography>
                )}
              </>
            )}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" color="secondary" onClick={handleAddClose}>
              Abbrechen
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(handleAddMessage)}
            >
              Hinzufügen
            </Button>
          </Box>
        </Box>
      </Modal>

    </Box>
  );
};

export default Messages;
