import * as endpoints from '../../constants/endpoints';
import { apiClient } from '../apiClient';

export const getList = async (data, success, error) => {
  const response = await apiClient.getListNew(endpoints.getMessageList,data, undefined, success, error);
  return response.data;
};

export const insertOne = async (data, success, error) => {
  const response = await apiClient.insertOne(endpoints.insertMessage, data, success, error);
  return response.data;
};

export const deleteOne = async (data, success, error) => {
  const response = await apiClient.post(endpoints.deleteMessage, data, success, error);
  return response.data;
};

export const markAsRead = async (data,success, error) => {
  console.log("markedAsRead: ", data)
  const response = await apiClient.post(endpoints.markMessageAsRead, data, success, error);
  return response.data;
};

export const countOwnMessages = async (data,success, error) => {
  console.log("markedAsRead: ", data)
  const response = await apiClient.getListNew(endpoints.countMessages, data, success, error);
  return response.data;
};