import React, { useState, useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import SignaturePad from 'react-signature-canvas';

import * as ContactPersonService from '../../../services/requests/contactPerson';
import * as OrganisationService from '../../../services/requests/organisation';
import { downloadFile } from '../../../helpers/utils';
import { getBusinessRegistrationListText } from '../../../helpers/database';
import { CompanyContext } from '../../pages/Company/CompanyContext';

import FormDatePicker from '../../common-components/FormDatePicker';
import BoxCenter from '../../common-components/BoxCenter';
import ModalWrapper from '../../common-components/ModalWrapper';
import FormTextField from '../../common-components/FormTextField';
import FormBooleanSelect from '../../common-components/FormBooleanSelect';
import FormCheckbox from '../../common-components/FormCheckbox';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import CustomButton from '../../common-components/CustomButton';
import CustomFormSelect from '../../common-components/CustomFormSelect/CustomFormSelect';
import { useGetElectionProposalQuery } from '../../../redux/services/ElectionProposalService';
import { fillEmailText } from '../../../helpers/fillEmailtext';
import { useSelector } from 'react-redux';
import { getElectionYear } from '../../../helpers/electionYear';
import { useGetListQuery } from '../../../redux/services/BusinessCategoryService';
import AlreadySendModal from '../../common-components/AlreadySendModal';

import { getList as getListForm } from '../../../services/requests/form';
import { CircularProgress } from '@mui/material';
import { swvTheme } from '../../../swvTheme';
import { FormSendingContext } from '../../pages/FormSending/FormSendingContext';
import { onlyUnique } from '../../../helpers/helperFunctions';


export default function ConsentSupportStatementPopup({ isOpen, onClose, mode }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  //mode = undefined || "single"
  const companyContext = useContext(CompanyContext);
  const companyData = companyContext?.companyData ?? undefined;
  const businessRegistrationList = companyContext?.businessRegistrationList ?? undefined;

  //mode = "multiple"
  const formSendingContext = useContext(FormSendingContext);
  const selectedCompanies = formSendingContext?.selectedCompanies ?? undefined;

  const userData = useSelector(state => state.app.userData);
  const [emailError, setEmailError] = useState(false);
  const [sigPad, setSigPad] = useState(null);
  const [onPersonDisabled, setOnPersonDisabled] = useState(false);
  const [alreadySendOpen, setAlreadySendOpen] = useState(false);
  const [alreadySendForms, setAlreadySendForms] = useState([]);
  const [isSending, setIsSending] = useState(0);

  const { control, reset, setValue } = useForm({
    defaultValues: {
      start: Date.now(),
      group: [],
      isRepresentation: false,
      isConference: false,
      isOrganisation: true,
      businessCategory: [],
      candidate: '',
      birthdate: '',
      businessList: '',
      isStandard: true,
      signature: '',
      sendMail: false,
      email: '',
      emailText: ''
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      getOrganisation();
    }
  }, [isOpen]);

  const disabled = (
    !companyData?.StandardWKO ||
    emailError ||
    !values?.start ||
    !values?.group ||
    !values?.businessCategory ||
    values?.candidate === '' ||
    !values?.birthdate ||
    values?.businessList === '' ||
    values?.isStandard === '');

  const getOrganisation = async (data) => {
    // ToDo: Refactor
    let sparten = [];
    let businessRegistrations = []

    if (mode === "multiple") {
      let addressRegistrations = selectedCompanies.map(el => el.AddressRegistrations);
      businessRegistrations = businessRegistrations.concat(...addressRegistrations).filter(el => el.BusinessRegistrations.length > 0).map(el => el.BusinessRegistrations[0]).filter(onlyUnique);
    } else {
      businessRegistrations = businessRegistrationList
    }

    if (businessRegistrations) {
      let sortedBusinessRegistrationList = [...businessRegistrations];
      sortedBusinessRegistrationList.sort((a, b) => parseInt(a.Business.BusinessSubcategory.Number) - parseInt(b.Business.BusinessSubcategory.Number))

      sortedBusinessRegistrationList?.forEach((element, index) => {

        //This adds all "Sparten" to "Fachog. und/oder Sparte", is not needed 
        /*if (element.Business?.BusinessSubcategory?.BusinessCategory?.Name && !sparten.some(item => item.Title === element.Business.BusinessSubcategory.BusinessCategory.Name)) {

          sparten.push({ Title: element.Business.BusinessSubcategory.BusinessCategory.Name, Counties: [element.Business.BusinessSubcategory.County] })

        } else {

          //Add all other Counties that the Company has Votingrights
          sparten = sparten.map((item) => {
            if (element?.Business?.BusinessSubcategory?.BusinessCategory?.Name && item.Title === element.Business.BusinessSubcategory.BusinessCategory.Name) {
              return { ...item, Counties: [...item.Counties, element.Business.BusinessSubcategory.County] }
            }
            return item
          })

        }*/

        if (element?.Business?.BusinessSubcategory?.Number && !sparten.some(item => item.Title === element.Business.BusinessSubcategory.Number)) {

          sparten.push({ Title: element.Business.BusinessSubcategory.Number, Counties: [element.Business.BusinessSubcategory.County] })

        } else {

          //Add all other Counties that the Company has Votingrights
          sparten = sparten.map((item) => {
            if (element.Business?.BusinessSubcategory?.Number && item.Title === element.Business.BusinessSubcategory.Number) {
              return { ...item, Counties: [...item.Counties, element.Business.BusinessSubcategory.County] }
            }
            return item
          })

        }
      })

      setBusinessCategories(sparten)
    }

    let counties = [];
    if (businessRegistrations) {
      businessRegistrations?.forEach(element => {
        if (!counties.includes(element.Business.BusinessSubcategory.County)) {
          counties.push(element.Business.BusinessSubcategory.County)
        }
      })
    }

    let tempElectionProposals = electionProposalData?.data?.filter(element => {
      if (element.ElectionYear === getElectionYear()) {
        if (element.County === "Salzburg" && counties.includes("S")) {
          return true;
        } else if (element.County === "Steiermark" && counties.includes("M")) {
          return true;
        } else if (counties.includes(element.County[0])) {
          return true;
        }
      }
      return false;
    });

    setElectionProposals(tempElectionProposals)

    reset({
      start: Date.now(),
      group: tempElectionProposals.map(element => element.Title),
      isRepresentation: false,
      isConference: false,
      isOrganisation: true,
      businessCategory: sparten.map(element => element.Title),
      candidate: mode === "multiple" ? undefined : companyData?.Master?.Person?.Firstname + ' ' + companyData?.Master?.Person?.Lastname,
      birthdate: mode === "multiple" ? undefined : companyData?.Master?.Person?.Birthdate,
      businessList: mode === "multiple" ? sparten.map(el => el.Title).join(', ') : getBusinessRegistrationListText(businessRegistrationList),
      isStandard: true,
      signature: '',
      sendMail: false,
      email: mode === "multiple" ? undefined : companyData.CommunicationData?.Email,
      emailText: mode === "multiple" ? undefined : await fillEmailText(companyData, userData, 'Ihre Unterstützungserklärung')
    });
  };

  const handleSubmit = async (forceSubmit, goalIdsToForceSend) => {
    let tempAlreadySendForms = [];

    let companies = mode === "multiple" ? [...selectedCompanies] : [companyData]

    for (let iteratorCompany = 0; iteratorCompany < companies.length; iteratorCompany++) {
      let company = companies[iteratorCompany];

      for (let i = 0; i < values.group.length; i++) {
        let group = values.group[i];

        for (let j = 0; j < values.businessCategory.length; j++) {
          let businessCategory = values.businessCategory[j];
          let tempBusinessCategory = businessCategories.find(el => el.Title === businessCategory);

          let county = undefined;
          let createForm = false

          if (electionProposals.find(element => element.Title === group)?.County === "Salzburg" && tempBusinessCategory.Counties.includes('S')) {
            county = 'S'
            createForm = true;
          } else if (electionProposals.find(element => element.Title === group)?.County === "Steiermark" && tempBusinessCategory.Counties.includes('M')) {
            county = 'M'
            createForm = true;
          } else if (businessCategories.find(item => item.Title === businessCategory)?.Counties.includes(electionProposals.find(element => element.Title === group)?.County[0])) {
            createForm = true;
            county = electionProposals.find(element => element.Title === group)?.County[0];
          }

          if (createForm === true) {
            let tempAlreadySendFormsForCandidate = await getListForm({ electionYear: getElectionYear(), title: "Zustimmungs- und Unterstützungserklärung", companyId: company.ID, businessSubcategories: [businessCategory], businessCategory: isNaN(parseFloat(businessCategory)) ? businessCategory : undefined, businessSubcategory: !isNaN(parseFloat(businessCategory)) ? businessCategory : undefined, candidates: [group] }, undefined, undefined)
            if (!forceSubmit && tempAlreadySendFormsForCandidate?.length > 0) {
              tempAlreadySendForms = tempAlreadySendForms.concat(tempAlreadySendFormsForCandidate)
              continue;
            }
            if (forceSubmit && !goalIdsToForceSend.includes(tempAlreadySendFormsForCandidate[0].FK_Goal)) {
              continue;
            }

            const data = {
              id: company.FK_ContactPerson,
              group: group,
              isRepresentation: values.isRepresentation == true,
              isConference: values.isConference == true,
              isOrganisation: values.isOrganisation == true,
              businessCategory: businessCategory,
              candidate: company?.Master?.Person?.Firstname + ' ' + company?.Master?.Person?.Lastname,
              birthdate: company?.Master?.Person?.Birthdate,
              businessList: values.businessList,
              isStandard: values.isStandard == true,
              signature: sigPad?.getCanvas()?.toDataURL('image/png'),
              sendMail: values.sendMail,
              email: values.email,
              emailText: values.emailText,
              sendCopy: values.sendCopy,
              electionYear: getElectionYear(),
              county: county,
              alreadySend: forceSubmit,
              goalId: tempAlreadySendFormsForCandidate && (tempAlreadySendFormsForCandidate.length > 0) ? tempAlreadySendFormsForCandidate[0].FK_Goal : undefined,
            };

            setIsSending((old) => old + 1)
            ContactPersonService.generateConsentSupportStatementPDF(data, (data) => {
              downloadFile(data, 'UnterstützungEinverständniserklärung.pdf');
              setIsSending((old => old - 1))
            },
              () => { setIsSending(old => old - 1) });
          }
        }
      }
    }

    if (tempAlreadySendForms.length > 0) {
      setAlreadySendOpen(true);
      setAlreadySendForms(tempAlreadySendForms);
    }
  };

  const { data: electionProposalData, error, isLoading } = useGetElectionProposalQuery({});
  const [electionProposals, setElectionProposals] = useState(electionProposalData?.data?.filter(element => element.ElectionYear === getElectionYear()))
  const { data: businessCategoryData, error: businessCategoryError, isLoading: businessCategoryIsLoading } = useGetListQuery(null);
  const [businessCategories, setBusinessCategories] = useState(businessCategoryData?.data ?? [])


  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Zustimmung und Unterstützung" className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}>
      <AlreadySendModal isOpen={alreadySendOpen} onClose={() => { setAlreadySendOpen(false) }} forms={alreadySendForms} handleSubmit={(force, goalIds) => { handleSubmit(force, goalIds) }} />
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormDatePicker
            name="start"
            label="Datum"
            control={control}
            required
            disabled
          />
          <CustomFormSelect
            name="group"
            label="Wählergruppe"
            control={control}
            valueKey='Title'
            options={electionProposals ?? []}
            titleKey='Title'
            chooseAll
            required
            multiple
          />
          <FormBooleanSelect
            label="Spartenvertretung"
            name="isRepresentation"
            control={control}
            value={values.isRepresentation}
            required
          />
          <FormBooleanSelect
            label="Spartenkonferenz"
            name="isConference"
            control={control}
            value={values.isConference}
            required
          />
          <FormBooleanSelect
            label="Fachorganisation"
            name="isOrganisation"
            control={control}
            value={values.isOrganisation}
            required
          />
          <CustomFormSelect
            name="businessCategory"
            label="Fachorganisationen"
            control={control}
            valueKey='Title'
            options={businessCategories ?? []}
            titleKey='Title'
            chooseAll
            required
            multiple
          />
          {/*<FormTextField
            readOnly
            name="businessList"
            label="Fachorganisation(en)"
            control={control}
  />*/}

          {mode !== "multiple" && <FormTextField
            name="candidate"
            label="Wahlvorschlag"
            control={control}
            disabled
            required
          />}
          {mode !== "multiple" && <FormDatePicker
            name="birthdate"
            label="Geburtsdatum"
            control={control}
            disabled
            required
          />}
          <FormBooleanSelect
            required
            label="Zusendung"
            name="isStandard"
            control={control}
            disabled={onPersonDisabled}
            value={values.isStandard}
            trueText="Standardadresse"
            falseText="Zustelladresse"
          />
        </LocalizationProvider>
      </FormControl>
      {mode !== "multiple" &&
        <>
          <span>
            Unterschrift:
          </span>
          <div className={cssClass.sigContainer}>
            <SignaturePad
              canvasProps={{ className: cssClass.sigPad }}
              ref={(ref) => setSigPad(ref)}
            />
            <div className={cssClass.clearSignature} onClick={() => sigPad.clear()}>
              Zurücksetzen
            </div>
          </div>
        </>}

      <Box className={cssClass.flexColumn}>
        <FormCheckbox
          name="sendMail"
          control={control}
          label="Email Zustellung"
        />
        {values.sendMail && mode !== "multiple" &&
          <FormTextField
            name="emailText"
            control={control}
            label="Email Text"
            multiline={true}
            rows={10}
          />
        }
        <FormCheckbox
          name="sendCopy"
          control={control}
          label="Kopie an folgende Email Adresse:"
        />
        <FormTextField
          name="email"
          label="Email"
          control={control}
          type="email"
          func={setEmailError}
        />
      </Box>

      <BoxCenter>
        <CustomButton
          disabled={isSending > 0}
          value={isSending === 0 ? 'PDF erstellen' : <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}><CircularProgress style={{ color: swvTheme.typography.white }} size={20}></CircularProgress></div>}
          onClick={() => handleSubmit()}
          sx={{ mb: 10, width: '300px !important' }}
        />
      </BoxCenter>

    </ModalWrapper>
  );
}
