import { Messages } from "../constants/messages";
import { IUser } from "../redux/slices/appSlice";
import { getNationalOrganisations } from "../services/requests/nationalOrganisations";

export const fillEmailText = async (companyData: any, userData: IUser, form: string) => {
    let person = companyData.Master.Person;

    let mailText = Messages.MailtextForm;
    let salutation = person.Gender === "M" ? "er Herr " : (person.Gender === "W" ? "e Frau " : "* ");
    if (person.Title_Business) {
        salutation += `${person.Title_Front.trim()} `;
    }
    if (person.Title_Front) {
        salutation += `${person.Title_Front.trim()} `;
    }
    if (person.Firstname) {
        salutation += `${person.Firstname.trim()} `;
    }
    if (person.Lastname) {
        salutation += `${person.Lastname.trim()}`;
    }

    let nationalOrganisation = null;
    if (userData) {
        nationalOrganisation = await loadNationalOrganisation(userData);
    }

    const nationalTel = nationalOrganisation?.[0]?.communicationData?.Telephone;

    mailText = mailText
        .replace('[Anrede]', salutation)
        .replace('[Formular]', form)
        .replace('[Name]', `${userData.Firstname} ${userData.Lastname}`)
        .replace('[Email]', `Email: ${userData.Email}`);

    if (nationalTel) {
        mailText = mailText.replace('[Tel]', `Tel: ${nationalTel}`);
    } else {
        mailText = mailText.replace(/\n?\[Tel\]?/, '');
    }

    return mailText;
};

const loadNationalOrganisation = async (userData: IUser) => {
    try {
        const response = await getNationalOrganisations({ county: userData.County });
        console.log('National organisations loaded:', response);
        return response;
    } catch (error) {
        console.error('Error loading national organisations:', error);
        return null;
    }
};