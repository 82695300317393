export const apiUrl = process.env.REACT_APP_BE_URL;
export const apiUrlEx = process.env.REACT_APP_BE_URL;

// activity.controller
export const insertActivity = "/insertActivity";
export const updateActivity = "/updateActivity";
export const getActivityList = "/getActivityList";
export const getActivityListEx = "/getActivityListEx";
export const deleteActivity = "/deleteActivity";
export const approveActivity = "/approveActivity";

// address.controller
export const getAddressList = "/getAddressList";

// nationalOrganisations.controller
export const getNationalOrganisations = "/national-organizations";

// addressRegistration.controller
export const insertAddressRegistration = "/insertAddressRegistration";
export const updateAddressRegistration = "/updateAddressRegistration";
export const deleteAddressRegistration = "/deleteAddressRegistration";
export const getAddressRegistrationList = "/getAddressRegistrationList";
export const getAddressRegistration = "/addressRegistration";

// auth.controller
export const authenticate = "/auth/authenticate";
export const resendTFA = "/auth/resendTFA";
export const submitTFA = "/auth/submitTFA";
export const forgotPassword = "/auth/forgotPassword";
export const setPassword = "/auth/setPassword";
export const verifyToken = "/auth/verifyToken"; // somewhere
export const refreshToken = "/auth/refresh";

// business.controller
export const getBusinessList = "/getBusinessList";

// businessCategory.controller
export const getBusinessCategoryList = "/getBusinessCategoryList";

// businessGroup.controller
export const getBusinessGroup = "/getBusinessGroup";
export const getBusinessGroupList = "/getBusinessGroupList";
export const insertBusinessGroup = "/insertBusinessGroup";
export const updateBusinessGroup = "/updateBusinessGroup";
export const deleteBusinessGroup = "/deleteBusinessGroup";

// businessRegistration.controller
export const getBusinessRegistrationList = "/getBusinessRegistrationList";
export const deleteBusinessRegistration = "/deleteBusinessRegistration";
export const insertBusinessRegistration = "/insertBusinessRegistration";
export const updateBusinessRegistration = "/updateBusinessRegistration";

// businessSubcategory.controller
export const getBusinessSubcategoryList = "BusinessSubcategory/getBusinessSubcategoryList";

//changeProposal.controller
export const getAllChangeProposal = "/changeProposal"
export const updateChangeProposal = "/changeProposal"
export const insertChangeProposal = "/changeProposal"

// comment.controller
export const insertComment = "/insertComment";
export const getCommentList = "/getCommentList";
export const getCommentListEx = "/getCommentListEx";
export const deleteComment = "/deleteComment";
export const updateComment = "/updateComment";
export const approveComment = "/approveComment";

// communicationData.controller
export const updateCommunicationData = "/updateCommunicationData";
export const insertCommunicationData = "/insertCommunicationData";
export const getCommunicationData = "/communicationData";

// company.controller
export const getCompanyList = "/getCompanyList";
export const getCompanyExportList = "/getCompanyExportList";
export const getCompanyListEx = "/getCompanyListEx";
export const getCompany = "/getCompany";
export const updateCompany = "/updateCompany";
export const removeMunicipality = "/removeMunicipality";
export const getMunicipalityCompanyList = "/getMunicipalityCompanyList";
export const checkCompanyWKO = "/checkCompanyWKO";
export const deleteCompany = "/deleteCompany";
export const insertCompany = "/insertCompany";
export const getCompanyPhotoSrc = "/getCompanyPhotoSrc";
export const getListPersonResultCount = "/getListPersonResultCount";
export const getListAddressResultCount = "/getListAddressResultCount";
export const getCompanyListBySubcategory = "/getCompanyListBySubcategory";
export const getCompanyBaseData = "/getCompanyBaseData";
export const getCompanyMasterContactPersonInfo = "/getCompanyMasterContactPersonInfo";

// contactPerson.controller
export const getContactPersonList = "/getContactPersonList";
export const deleteContactPerson = "/deleteContactPerson";
export const insertContactPerson = "insertContactPerson";
export const getContactPerson = "/contactPerson";
export const generateMembershipPDF = "/generateMembershipPDF";
export const generateElectoralListPDF = "/generateElectoralListPDF";
export const generateVotingCardPDF = "/generateVotingCardPDF";
export const generateSupportStatementPDF = "/generateSupportStatementPDF";
export const generateConsentSupportStatementPDF = "/generateConsentSupportStatementPDF";
export const scanCard = "/scanCard";

// favorite.controller
export const insertFavorite = "/insertFavorite";
export const getFavoriteList = "/getFavoriteList";
export const deleteFavorite = "/deleteFavorite";


// file.controller
export const getFileList = "/file"; //without query or with companyid
export const insertFile = "/file";
export const deleteFile = "/file/id=";
export const downloadFile = "/file"; //query for id
export const getBase64Uri = "/file/base64"; //query for id
export const getPhotoList = "/file/photos";
export const getSwvInfoFiles = "/file/swvInfoFiles";
export const postStaticFile = "/file/static";


//message.controller
export const getMessageList = "/message";
export const insertMessage = "/message";
export const deleteMessage = "/message/delete";
export const markMessageAsRead = '/message/read';
export const countMessages = '/message/count';

// form.controller
export const getForm = "/form"; //without query or with companyid
export const deleteForm = "/form";
export const updateForm = "/form";
export const updateFormStatus = "/form/status";
export const insertForm = "/form";

// form.controller
export const getElectionProposal = "/electionproposal"; //without query or with companyid
export const deleteElectionProposal = "/electionproposal";
export const updateElectionProposal = "/electionproposal";
export const insertElectionProposal = "/electionproposal";


//ToDo: Remove everything that has to do with photos...
// photo.controller
export const getPhotoSrc = "/getPhotoSrc";
export const deletePhoto = "/deletePhoto";
export const downloadPhoto = "/downloadPhoto";


// function.controller
export const getFunctionList = "/getFunctionList";
export const insertFunction = "/insertFunction";
export const updateFunction = "/updateFunction";
export const deleteFunction = "/deleteFunction";


// goal.controller
export const getGoalList = "/getGoalList";
export const insertGoal = "/insertGoal";
export const updateGoal = "/updateGoal";
export const updateGoalStatusNew = "/goal/status";

export const deleteGoal = "/deleteGoal";

// goalStatus.controller
export const getGoalStatusList = "/getGoalStatusList";
export const getGoalStatusListNew = "/GoalStatus";
export const updateGoalStatus = "/updateGoalStatus";

export const goalStatusEnumController = "/goalStatusEnum";

export const treeViewController = "/treeView";
export const treeViewControllerGetFoundCompanies = "/treeView/search";


// history.controller
export const getHistoryList = "/getHistoryList";

// honor.controller
export const getHonorList = "/getHonorList";
export const updateHonor = "/updateHonor";
export const insertHonor = "/insertHonor";
export const deleteHonor = "/deleteHonor";

// member.controller
export const getMemberList = "/member";
export const getListOfMember = "/member/getListOfMember";
export const insertMember = "/member";
export const updateMember = "/member";
export const removeMember = "/member";

// municipality.controller
export const getMunicipalityList = "/municipalityList";
export const insertMunicipality = "/insertMunicipality";
export const updateMunicipality = "/updateMunicipality";
export const deleteMunicipality = "/deleteMunicipality";

// organisation.controller
export const getOrganisationList = "/getOrganisationList";
export const insertOrganisation = "/insertOrganisation";
export const updateOrganisation = "/updateOrganisation";
export const deleteOrganisation = "/deleteOrganisation";

// panel.controller
export const getPanelList = "/getPanelList";
export const insertPanel = "/insertPanel";
export const updatePanel = "/updatePanel";
export const deletePanel = "/deletePanel";

// person.controller
export const getPersonList = "/getPersonList";
export const getPersonSearchExport = "/getPersonSearchExport";
export const getPersonListExport = "/getPersonListExport";
export const getPersonListExportCSV = "/getPersonListExportCSV";
export const getPerson = "/getPerson";
export const insertPerson = "/insertPerson";
export const updatePerson = "/updatePerson";
export const deletePerson = "/deletePerson";
export const getPersonPhotoSrc = "/getPersonPhotoSrc";

// profile.controller
export const getProfile = "/getProfile";
export const updateProfile = "/updateProfile";
export const getProfilePhotoSrc = "/getProfilePhotoSrc";
export const checkProfileEmail = "/checkProfileEmail";
export const checkProfileUsername = "/checkProfileUsername";

// user.controller
export const insertUser = "/insertUser";
export const updateUser = "/updateUser";
export const getUserList = "/getUserList";
export const deleteUser = "/deleteUser";
export const inviteUser = "/inviteUser";
export const getCurrentUser = "/getCurrentUser"
