import React, { useRef, useState } from 'react';
import { Box, Button, IconButton, Typography, LinearProgress, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { uploadStaticFile } from '../../../services/requests/file';

interface UploadComponentProps {
  addToSWVInfo: boolean;
  onFileUpload: (files: File[]) => void;
}

const UploadComponent: React.FC<UploadComponentProps> = ({ addToSWVInfo, onFileUpload }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [notes, setNotes] = useState<string[]>([]);
  const [uploadStatus, setUploadStatus] = useState<'select' | 'selected' | 'uploading' | 'done'>('select');
  const [progress, setProgress] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const parsedFiles = uploadedFiles.map(file => (typeof file === 'string' ? JSON.parse(file) : file));

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const notesArray = new Array(files.length).fill('');
      setSelectedFiles(files);
      setNotes(notesArray);
      setUploadStatus('selected');
    }
  };

  const onChooseFile = () => {
    inputRef.current?.click();
  };

  const clearFileInput = (index?: number) => {
    if (selectedFiles.length === 1 || index === undefined) {
      if (inputRef.current) inputRef.current.value = '';
      setSelectedFiles([]);
      setNotes([]);
      setProgress(0);
      setUploadStatus('select');
    } else {
      setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
      setNotes(notes.filter((_, i) => i !== index));
    }
  };

  const handleUpload = async () => {
    if (uploadStatus === 'done') {
      clearFileInput();
      return;
    }

    try {
      setUploadStatus('uploading');

      const formData = new FormData();
      selectedFiles.forEach(file => formData.append('files', file));
      formData.append('notes', JSON.stringify(notes));
      formData.append('AddToSWVInfo', String(addToSWVInfo));

      const response = await uploadStaticFile(formData, {
        //@ts-ignore
        onUploadProgress: (progressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted);
          }
        },
      });
      //@ts-ignore
      if (response.data.newFiles && response.data.newFiles.length > 0) {
        onFileUpload(response?.data.newFiles);
        setUploadedFiles(prev => [...prev, ...response?.data.newFiles]);
      }

      setUploadStatus('done');
      setSelectedFiles([]);
    } catch (error) {
      console.error('Fehler beim Upload:', error);
      setUploadStatus('select');
    }
  };

  return (
    <Box sx={{ pl: 2,pr: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Button variant="contained" onClick={onChooseFile} sx={{ marginBottom: 2 }}>
        Datei auswählen
        <input
          type="file"
          ref={inputRef}
          hidden
          multiple
          onChange={handleFileChange}
        />
      </Button>

      {selectedFiles.length > 0 && (
        <Box sx={{ width: '100%', maxWidth: '800px' }}>
          {selectedFiles.map((file, i) => (
            <Box key={i} sx={{ marginBottom: '0.5em' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {i + 1}. {file.name}
                </Typography>
                <IconButton onClick={() => clearFileInput(i)}>
                  <Close fontSize="small" />
                </IconButton>
              </Box>
              <TextField
                variant="outlined"
                placeholder="Notiz"
                fullWidth
                value={notes[i]}
                onChange={(event) => {
                  const updatedNotes = [...notes];
                  updatedNotes[i] = event.target.value;
                  setNotes(updatedNotes);
                }}
              />
            </Box>
          ))}

          {uploadStatus === 'done' && (
            <Typography sx={{ mt: 2, color: 'green' }}>Ihr Upload war erfolgreich</Typography>
          )}
          {uploadStatus === 'uploading' && (
            <LinearProgress variant="determinate" value={progress} sx={{ mt: 2 }} />
          )}
          <Button variant="contained" onClick={handleUpload} sx={{ mt: 2 }}>
            Hochladen
          </Button>
        </Box>
      )}

      {parsedFiles.length > 0 && (
        <Box sx={{ width: '100%', maxWidth: '800px', mt: 3 }}>
          <Typography variant="h6">Hochgeladene Dokumente</Typography>
          {parsedFiles.map((file, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {file.FileName}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default UploadComponent;
