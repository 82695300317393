import React, { useState, useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import SignaturePad from 'react-signature-canvas';

import ModalWrapper from '../../common-components/ModalWrapper';
import FormTextField from '../../common-components/FormTextField';
import FormBooleanSelect from '../../common-components/FormBooleanSelect';

import * as ContactPersonService from '../../../services/requests/contactPerson';
import { downloadFile } from '../../../helpers/utils';
import { CompanyContext } from '../../pages/Company/CompanyContext';
import FormDatePicker from '../../common-components/FormDatePicker';
import BoxCenter from '../../common-components/BoxCenter';
import FormCheckbox from '../../common-components/FormCheckbox';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import CustomButton from '../../common-components/CustomButton';
import { useSelector } from 'react-redux';
import { fillEmailText } from '../../../helpers/fillEmailtext';
import { getElectionYear } from '../../../helpers/electionYear';
import AlreadySendModal from '../../common-components/AlreadySendModal';
import { getList as getListForm } from '../../../services/requests/form';
import { CircularProgress } from '@mui/material';
import { swvTheme } from '../../../swvTheme';
import { onlyUnique } from '../../../helpers/helperFunctions';
import { FormSendingContext } from '../../pages/FormSending/FormSendingContext';

export default function VotingCardPopUp({ isOpen, onClose, mode }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  //mode = undefined || "single"
  const companyContext = useContext(CompanyContext);
  const companyData = companyContext?.companyData ?? undefined;
  const businessRegistrationList = companyContext?.businessRegistrationList ?? undefined;

  //mode = "multiple"
  const formSendingContext = useContext(FormSendingContext);
  const selectedCompanies = formSendingContext?.selectedCompanies ?? undefined;

  const userData = useSelector(state => state.app.userData);

  const [emailError, setEmailError] = useState(false);
  const [sigPad, setSigPad] = useState(null);
  const [alreadySendOpen, setAlreadySendOpen] = useState(false);
  const [alreadySendForms, setAlreadySendForms] = useState([]);
  const [isSending, setIsSending] = useState(0);

  const clear = () => sigPad.clear();

  const { control, reset } = useForm({
    defaultValues: {
      start: Date.now(),
      isStandard: true,
      isDispatch: true,
      signature: '',
      sendCopy: false,
      sendMail: false,
      email: '',
      emailText: ''
    },
  });

  const values = useWatch({ control });

  useEffect(() => {
    const setupForm = async () => {
      const emailText = mode === "multiple"
        ? undefined
        : await fillEmailText(
          companyData,
          userData,
          'den Antrag um Aufnahme eines ruhenden Mitglieds in die Wählerliste(n)'
        );

      reset({
        start: Date.now(),
        isStandard: true,
        isDispatch: true,
        signature: '',
        sendMail: false,
        sendCopy: false,
        email: mode === "multiple" ? undefined : companyData.CommunicationData?.Email,
        emailText,
      });
    };

    if (isOpen) {
      setupForm();
    }
  }, [isOpen]);

  const disabled =
    emailError ||
    !values?.start ||
    values?.isStandard === '' ||
    values?.isDispatch === '';

  const handleSubmit = async (forceSubmit, goalIdsToForceSend) => {
    let counties = [];
    let tempForms = [];

    let companies = mode === "multiple" ? [...selectedCompanies] : [companyData]

    for (let iteratorCompany = 0; iteratorCompany < companies.length; iteratorCompany++) {
      let company = companies[iteratorCompany];

      if (mode === "multiple") {
        counties = company.AddressRegistrations.map(el => el.County).filter(onlyUnique);
      } else {
        counties = businessRegistrationList.map(el => el.AddressRegistration.County).filter(onlyUnique);
      }

      for (let i = 0; i < counties.length; i++) {
        let county = counties[i];
        let tempAlreadySendForms = await getListForm({ title: "Wahlkartenantrag", companyId: company.ID, county }, undefined, undefined)
        if (!forceSubmit && tempAlreadySendForms?.length > 0) {
          tempForms = tempForms.concat(tempAlreadySendForms);
          continue;
        }
        if (forceSubmit && !goalIdsToForceSend.includes(tempAlreadySendForms[0].FK_Goal)) {
          continue;
        }

        const data = {
          id: company.FK_ContactPerson,
          isStandard: values.isStandard,
          isDispatch: values.isDispatch,
          signature: sigPad?.getCanvas().toDataURL('image/png'),
          sendMail: values.sendMail,
          email: values.email,
          sendCopy: values.sendCopy,
          emailText: mode === "multiple" ? await fillEmailText(company, userData, 'den Antrag um Aufnahme eines ruhenden Mitglieds in die Wählerliste(n)') : values.emailText,
          electionYear: getElectionYear(),
          alreadySend: forceSubmit,
          county: county,
          goalId: tempAlreadySendForms && (tempAlreadySendForms.length > 0) ? tempAlreadySendForms[0].FK_Goal : undefined,
        };

        setIsSending((old) => old + 1)
        await ContactPersonService.generateVotingCardPDF(data, (data) => {
          downloadFile(data, 'Wahlkartenantrag.pdf');
          setIsSending((old => old - 1))
        });
      }
    }

    if (tempForms.length > 0) {
      setAlreadySendOpen(true);
      setAlreadySendForms(tempForms)
    }
  };

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose} title="Wahlkartenantrag" className={cssClass.swvModalResponsiveMods /* ToDo: check if this is wanted + " " + classes.wrapper*/}>
      <AlreadySendModal isOpen={alreadySendOpen} onClose={() => { setAlreadySendOpen(false) }} forms={alreadySendForms} handleSubmit={(force, goalIdsToForceSend) => handleSubmit(force, goalIdsToForceSend)} />
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormDatePicker
            name="start"
            label="Datum"
            control={control}
            required
            disabled
          />
          <FormBooleanSelect
            required
            label="Zusendung"
            name="isStandard"
            control={control}
            value={values.isStandard}
            trueText="Standardadresse"
            falseText="Zustelladresse"
          />
          <FormBooleanSelect
            required
            label="Zustellung"
            name="isDispatch"
            value={values.isDispatch}
            control={control}
            trueText="Postzustellung"
            falseText="Abholung"
          />
        </LocalizationProvider>
      </FormControl>
      {mode !== "multiple" && <>
        <span>
          Unterschrift:
        </span>
        <Box className={cssClass.sigContainer}>
          <SignaturePad
            canvasProps={{ className: cssClass.sigPad }}
            ref={(ref) => setSigPad(ref)}
          />
          <div className={cssClass.clearSignature} onClick={() => clear()}>
            Zurücksetzen
          </div>
        </Box>
      </>}
      <Box className={cssClass.flexColumn}>
        <FormCheckbox
          name="sendMail"
          control={control}
          label="Email Zustellung"
        />
        {values.sendMail && mode !== "multiple" &&
          <FormTextField
            name="emailText"
            control={control}
            label="Email Text"
            multiline={true}
            rows={10}
          />
        }
      </Box>
      <Box className={cssClass.flexColumn}>

        <FormCheckbox
          name="sendCopy"
          control={control}
          label="Kopie an folgende Email Adresse:"
        />
        <FormTextField
          name="email"
          label="Email"
          control={control}
          type="email"
          func={setEmailError}
        />
      </Box>

      <BoxCenter>
        <CustomButton
          disabled={isSending > 0}
          value={isSending === 0 ? 'PDF erstellen' : <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}><CircularProgress style={{ color: swvTheme.typography.white }} size={20}></CircularProgress></div>}
          onClick={() => handleSubmit()}
          sx={{ mb: 10, width: '300px !important' }}
        />
      </BoxCenter>
    </ModalWrapper>
  );
}
