import React, { useState, useContext, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

import SignaturePad from 'react-signature-canvas';

import * as ContactPersonService from '../../../services/requests/contactPerson';
import * as OrganisationService from '../../../services/requests/organisation';
import { downloadFile } from '../../../helpers/utils';
import { getBusinessRegistrationListText } from '../../../helpers/database';
import { CompanyContext } from '../../pages/Company/CompanyContext';

import FormDatePicker from '../../common-components/FormDatePicker';
import BoxCenter from '../../common-components/BoxCenter';
import ModalWrapper from '../../common-components/ModalWrapper';
import FormTextField from '../../common-components/FormTextField';
import FormCheckbox from '../../common-components/FormCheckbox';

import { useStyles } from './styles';
import { useGlobalStyles } from '../../../customStyles';
import CustomButton from '../../common-components/CustomButton';
import CustomFormSelect from '../../common-components/CustomFormSelect/CustomFormSelect';
import { useGetElectionProposalQuery } from '../../../redux/services/ElectionProposalService';
import { fillEmailText } from '../../../helpers/fillEmailtext';
import { useSelector } from 'react-redux';
import { getElectionYear } from '../../../helpers/electionYear';
import { useGetListQuery } from '../../../redux/services/BusinessCategoryService';
import AlreadySendModal from '../../common-components/AlreadySendModal';
import { getList as getListForm } from '../../../services/requests/form';
import { CircularProgress } from '@mui/material';
import { swvTheme } from '../../../swvTheme';
import { FormSendingContext } from '../../pages/FormSending/FormSendingContext';
import { onlyUnique } from '../../../helpers/helperFunctions';

export default function SupportStatementPopUp({ isOpen, onClose, mode }) {
  const classes = useStyles();
  const cssClass = useGlobalStyles();

  //mode = undefined || "single"
  const companyContext = useContext(CompanyContext);
  const companyData = companyContext?.companyData ?? undefined;
  const businessRegistrationList = companyContext?.businessRegistrationList ?? undefined;

  //mode = "multiple"
  const formSendingContext = useContext(FormSendingContext);
  const selectedCompanies = formSendingContext?.selectedCompanies ?? undefined;

  const userData = useSelector(state => state.app.userData);
  const [emailError, setEmailError] = useState(false);
  const [sigPad, setSigPad] = useState(null);
  const [alreadySendOpen, setAlreadySendOpen] = useState(false);
  const [alreadySendForms, setAlreadySendForms] = useState([]);
  const [isSending, setIsSending] = useState(0);

  const clear = () => sigPad.clear();

  const { control, reset, setValue } = useForm({
    defaultValues: {
      start: Date.now(),
      candidate: [],
      businessList: '',
      signature: '',
      sendMail: false,
      email: '',
      emailText: '',
      businessCategory: []
    },
  });

  const values = useWatch({ control });

  const { data: electionProposalData, error, isLoading } = useGetElectionProposalQuery({});
  const { data: businessCategoryData, error: businessCategoryError, isLoading: businessCategoryIsLoading } = useGetListQuery(null);
  const [businessCategories, setBusinessCategories] = useState(businessCategoryData?.data ?? [])

  useEffect(() => {
    if (isOpen) {
      getOrganisation();
    }
  }, [isOpen, electionProposalData]);

  const disabled =
    !companyData?.StandardWKO ||
    emailError ||
    !values?.start ||
    values?.candidate === '' ||
    values?.businessList === '';

  const getOrganisation = async (data) => {
    let sparten = [];

    let businessRegistrations = []

    if (mode === "multiple") {
      let addressRegistrations = selectedCompanies.map(el => el.AddressRegistrations);
      businessRegistrations = businessRegistrations.concat(...addressRegistrations).filter(el => el.BusinessRegistrations.length > 0).map(el => el.BusinessRegistrations[0]).filter(onlyUnique);
    } else {
      businessRegistrations = businessRegistrationList
    }


    if (businessRegistrations) {
      let sortedBusinessRegistrationList = [...businessRegistrations];
      sortedBusinessRegistrationList.sort((a, b) => parseInt(a.Business.BusinessSubcategory.Number) - parseInt(b.Business.BusinessSubcategory.Number))

      sortedBusinessRegistrationList?.forEach((element, index) => {
        if (!sparten.some(item => item.Title === element.Business.BusinessSubcategory.Number)) {
          sparten.push({ Title: element.Business.BusinessSubcategory.Number, Counties: [element.Business.BusinessSubcategory.County] })
        } else {
          //Add all other Counties that the Company has Votingrights
          sparten = sparten.map((item) => {
            if (item.Title === element.Business.BusinessSubcategory.Number) {
              return { ...item, Counties: [...item.Counties, element.Business.BusinessSubcategory.County] }
            }
            return item
          })
        }
      })

      setBusinessCategories(sparten)
    }

    let counties = [];
    if (businessRegistrations) {
      businessRegistrations?.forEach(element => {
        if (!counties.includes(element.Business.BusinessSubcategory.County)) {
          counties.push(element.Business.BusinessSubcategory.County)
        }
      })
    }
    let tempElectionProposals = electionProposalData?.data?.filter(element => {
      if (element.ElectionYear === getElectionYear()) {
        if (element.County === "Salzburg" && counties.includes("S")) {
          return true;
        } else if (element.County === "Steiermark" && counties.includes("M")) {
          return true;
        } else if (counties.includes(element.County[0])) {
          return true;
        }
      }
      return false;
    });

    setElectionProposals(tempElectionProposals)

    reset({
      start: Date.now(),
      businessCategory: sparten.map(element => element.Title),
      candidate: tempElectionProposals.map(element => element.Title),
      businessList: mode === "multiple" ? undefined : getBusinessRegistrationListText(
        businessRegistrationList,
      ),
      signature: '',
      sendMail: false,
      email: mode === "multiple" ? undefined : companyData.CommunicationData?.Email,
      emailText: mode === "multiple" ? undefined : await fillEmailText(companyData, userData, 'Ihre Unterstützungserklärung')
    });
  };

  const handleSubmit = async (forceSubmit, goalIdsToForceSend) => {
    let tempAlreadySendForms = [];

    let companies = mode === "multiple" ? [...selectedCompanies] : [companyData]

    for (let iteratorCompany = 0; iteratorCompany < companies.length; iteratorCompany++) {
      let company = companies[iteratorCompany];

      for (let i = 0; i < values.candidate.length; i++) {
        let element = values.candidate[i];

        let countyChar = ''
        let candidate = electionProposals.find(item => item.Title === element);
        if (candidate.County === "Steiermark") {
          countyChar = 'M';
        } else {
          countyChar = candidate.County[0];
        }

        let tempBusinessCategories = []
        let tempRawBusinessCategories = []
        values.businessCategory.forEach(item => {
          let temp = businessCategories.find(tempBusinessCategory => tempBusinessCategory.Title === item);
          if (temp?.Counties?.includes(countyChar)) {
            tempBusinessCategories.push(item)
            tempRawBusinessCategories.push({ ...temp, Counties: [countyChar] })
          }
        })

        for (let i = 0; i < tempBusinessCategories.length; i++) {
          let tempAlreadySendFormsForCandidate = await getListForm({ electionYear: getElectionYear(), title: "Unterstützungserklärung", companyId: company.ID, businessSubcategories: [tempBusinessCategories[i]], businessCategory: isNaN(parseFloat(tempBusinessCategories[i])) ? tempBusinessCategories[i] : undefined, businessSubcategory: !isNaN(parseFloat(tempBusinessCategories[i])) ? tempBusinessCategories[i] : undefined, candidates: [element] }, undefined, undefined)
          if (!forceSubmit && tempAlreadySendFormsForCandidate?.length > 0) {
            tempAlreadySendForms = tempAlreadySendForms.concat(tempAlreadySendFormsForCandidate.map(el => ({ ...el, companyName: company.Name })))
            continue;
          }
          if (forceSubmit && !goalIdsToForceSend.includes(tempAlreadySendFormsForCandidate[0].FK_Goal)) {
            continue;
          }

          const data = {
            id: company.FK_ContactPerson,
            candidate: element,
            businessList: tempBusinessCategories[i],
            rawBusinessList: [tempRawBusinessCategories[i]],
            signature: sigPad.getCanvas().toDataURL('image/png'),
            sendMail: values.sendMail,
            email: mode === "multiple" ? company.CommunicationData?.Email : values.email,
            sendCopy: values.sendCopy,
            emailText: mode === "multiple" ? await fillEmailText(company, userData, 'Ihre Unterstützungserklärung') : values.emailText,
            electionYear: getElectionYear(),
            alreadySend: forceSubmit,
            goalId: tempAlreadySendFormsForCandidate && (tempAlreadySendFormsForCandidate.length > 0) ? tempAlreadySendFormsForCandidate[0].FK_Goal : undefined,
            county: tempRawBusinessCategories[i].Counties[0],
            businessCategory: tempBusinessCategories[i]
          };

          setIsSending((old) => old + 1)
          ContactPersonService.generateSupportStatementPDF(data, (data) => {
            downloadFile(data, 'Unterstützungserklärung.pdf');
            setIsSending((old => old - 1));
          });
        }
      }
    }

    if (tempAlreadySendForms.length > 0) {
      setAlreadySendOpen(true);
      setAlreadySendForms(tempAlreadySendForms);
    }
  };

  const [electionProposals, setElectionProposals] = useState(electionProposalData?.data)

  return (
    <ModalWrapper
      isOpen={isOpen}
      onClose={onClose}
      title="Unterstützungserklärung"
      className={cssClass.swvModalResponsiveMods + " " + classes.wrapper}
    >
      <AlreadySendModal mode="multiple" isOpen={alreadySendOpen} onClose={() => { setAlreadySendOpen(false) }} forms={alreadySendForms} handleSubmit={(force, goalIds) => { handleSubmit(force, goalIds) }} />
      <FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormDatePicker
            name="start"
            label="Datum"
            control={control}
            required
            disabled
          />
          <CustomFormSelect
            name="candidate"
            label="Wahlvorschlag"
            control={control}
            valueKey='Title'
            options={electionProposals ?? []}
            titleKey='Title'
            chooseAll
            required
            multiple
          />
          <CustomFormSelect
            name="businessCategory"
            label="Fachorganisation"
            control={control}
            valueKey='Title'
            options={businessCategories ?? []}
            titleKey='Title'
            chooseAll
            required
            multiple
          />
        </LocalizationProvider>
      </FormControl>
      <span>Unterschrift:</span>
      <div className={cssClass.sigContainer}>
        <SignaturePad
          canvasProps={{ className: cssClass.sigPad }}
          ref={(ref) => setSigPad(ref)}
        />
        <div className={cssClass.clearSignature} onClick={() => clear()}>
          Zurücksetzen
        </div>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
        <FormCheckbox
          name="sendMail"
          control={control}
          label="Email Zustellung"
        />
        {values.sendMail && mode !== "multiple" &&
          <FormTextField
            name="emailText"
            control={control}
            label="Email Text"
            multiline={true}
            rows={10}
          />
        }
        <FormCheckbox
          name="sendCopy"
          control={control}
          label="Kopie an folgende Email Adresse:"
        />
        <FormTextField
          name="email"
          label="Email"
          control={control}
          type="email"
          func={setEmailError}
        />
      </Box>

      <BoxCenter>
        <CustomButton
          disabled={isSending > 0}
          value={isSending === 0 ? 'PDF erstellen' : <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}><CircularProgress style={{ color: swvTheme.typography.white }} size={20}></CircularProgress></div>}
          onClick={() => handleSubmit()}
          sx={{ mb: 10, width: '300px !important' }}
        />
      </BoxCenter>
    </ModalWrapper>
  );
}
